<template>
  <div id="smartai_score_manage_page" style="margin: 20px" v-cloak>
    <div class="title">
      <span class="s">实训成绩</span>
    </div>
    <el-dialog
      title="得分详情"
      :visible.sync="dialogVisible"
      size="mini"
      top="5vh"
      width="90%"
    >
      <div
        class="radio_box"
        style="margin-bottom: 10px; display: flex; justify-content: center"
      >
        <el-radio-group v-model="curActive">
          <el-radio :label="1">列表</el-radio>
          <el-radio :label="2">图表</el-radio>
        </el-radio-group>
      </div>
      <template v-if="curActive == 1">
        <el-descriptions border size="medium" :column="2">
          <el-descriptions-item label="用户名称">{{
            current_user_info.username
          }}</el-descriptions-item>
          <el-descriptions-item label="学校">{{
            current_user_info.school_name
          }}</el-descriptions-item>
          <el-descriptions-item label="班级">{{
            current_user_info.class_name
          }}</el-descriptions-item>
          <el-descriptions-item label="班级排名">{{
            current_user_info.class_bank
          }}</el-descriptions-item>
        </el-descriptions>
        <el-table
          :data="operationTableData"
          border
          show-summary
          style="width: 100%; margin-top: 10px"
        >
          <el-table-column prop="name" label="评分项" width="180">
          </el-table-column>
          <el-table-column prop="percent" label="占比"> </el-table-column>
          <el-table-column prop="score" label="得分"> </el-table-column>
        </el-table>

        <el-tabs type="border-card" style="margin-top: 10px">
          <el-tab-pane
            class="tab_plane2"
            :label="'流程分(' + current_flow_total + '分)'"
          >
            <el-table
              :data="flowTableData"
              v-loading="loading"
              show-summary
              :summary-method="getSummaries"
              style="width: 100%"
            >
              <el-table-column prop="name" label="任务名称" width="130">
              </el-table-column>
              <el-table-column prop="desc" label="任务描述"> </el-table-column>
              <el-table-column prop="default_score" label="分值" width="130">
              </el-table-column>
              <el-table-column prop="score" label="得分" width="130">
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane class="tab_plane2" label="运营分">
            <el-table :data="orderTableData" style="width: 100%">
              <el-table-column prop="valid_orders_count" label="有效订单">
              </el-table-column>
              <el-table-column prop="return_orders_count" label="退货/退款订单数">
              </el-table-column>
              <!-- <el-table-column prop="refund_orders_count" label="退款订单数">
              </el-table-column> -->
              <el-table-column prop="orders_total" label="总订单数">
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane class="tab_plane2" label="利润分">
            <el-table :data="profitTableData" style="width: 100%">
              <el-table-column prop="profit_fee" label="利润">
              </el-table-column>
              <el-table-column prop="sales_fee" label="销售额">
              </el-table-column>
              <el-table-column prop="purchase_fee" label="采购成本">
              </el-table-column>
              <el-table-column prop="order_ship_fee" label="订单物流成本">
              </el-table-column>

              <el-table-column prop="adversting_fee" label="推广费">
              </el-table-column>
              <el-table-column prop="platform_commission_fee" label="平台佣金">
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane class="tab_plane2" label="教师评分">
            <el-table :data="teacherTableData" style="width: 100%">
              <el-table-column
                prop="module_name"
                label="评分模块"
                width="200px"
              >
              </el-table-column>
              <el-table-column prop="score" label="教师评分" width="200px">
                <template slot-scope="scope">
                  <span v-if="scope.row.is_reviewed == 0">教师未评</span>
                  <span v-if="scope.row.is_reviewed == 1">{{
                    scope.row.score
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="compnents" label="教师评语">
                <template slot-scope="scope">
                  <span v-if="scope.row.is_reviewed == 0">教师未评</span>
                  <span v-if="scope.row.is_reviewed == 1">{{
                    scope.row.compnents
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </template>
      <template v-if="curActive == 2">
        <scoreChart :cur_row="curRow"></scoreChart>
      </template>
    </el-dialog>

    <searchToolbar
      ref="scorebanktoolbar"
      :is_show_school="false"
      :is_show_teacher="false"
      :is_show_btn_statistics="false"
      :school_list="school_list"
      :class_list="class_list"
      :is_show_username="true"
      :is_show_class="true"
      :is_show_sort="true"
      @st-search-click="bank_search"
      @st-export-click="bank_export"
      @st-sort-click="bank_search"
    >
    </searchToolbar>

    <div>
      <el-table
        :data="scoreBanktableData"
        style="width: 100%"
        v-loading="loading"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        class="table"
      >
        <el-table-column prop="bank" label="排行" width="100">
        </el-table-column>
        <el-table-column prop="member_name" label="用户名"> </el-table-column>
        <el-table-column prop="member_truename" label="真实姓名">
        </el-table-column>
        <el-table-column prop="school_name" label="学校"> </el-table-column>
        <el-table-column prop="class_name" label="班级"> </el-table-column>
        <el-table-column prop="total_score" label="总分"> </el-table-column>
        <el-table-column prop="flow_score" label="流程分"> </el-table-column>
        <el-table-column prop="operation_score" label="运营分">
        </el-table-column>
        <el-table-column prop="profit_score" label="利润分"> </el-table-column>
        <el-table-column prop="teacher_score" label="教师总分">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="openScoreDetail(scope.row)"
              >查看得分详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          small
          style="margin-top: 15px"
          layout="prev, pager, next"
          @current-change="handleBankCurrentChange"
          :page-size="bankpageSize"
          :total="scoreBankTotalCount"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getScoreBankList,
  getOperationComponentsS,
  getUserModuleScoreDetail,
  getFlowDetailS,
  exportTrainingScore,
} from "@/api/admin.js";
import funDownload from "@/utils/exportExcel.js";
import scoreChart from "@/views/admin/school/components/new_score_chart.vue";
import searchToolbar from "@/views/admin/school/components/search_compontent.vue";
export default {
  data() {
    return {
      curActive: 1,
      curRow: {},
      activeName: "1",
      loading: true,
      search_data: {},
      search_school_name: "",
      teacher_school_id: "",
      school_list: [],
      class_list: [],
      scoreBanktableData: [],
      scoreBankTotalCount: 0,
      orderBankTableData: [],
      orderBankTotalCount: 0,
      profitBankTotalCount: 0,
      bankpageSize: 8,
      bankpage: 1,
      dialogVisible: false,
      current_school_student_string: "",
      current_user_info: {
        username: "",
        school_name: "",
        class_name: "",
        class_bank: "",
      },
      operationComponents: [],
      orderTableData: [
        {
          orders_total: 0,
          refund_orders_count: 0,
          return_orders_count: 0,
          valid_orders_count: 0,
        },
      ],
      operationTableData: [],
      profitTableData: [
        {
          profit_fee: 20,
          platform_fee: 10,
          sales_fee: 20,
          platform_commission_fee: 10,
          adversting_fee: 5,
          purchase_fee: 10,
          order_ship_fee: 10,
          amazon_ship_fee: 5,
        },
      ],
      teacherTableData: [],
      flowTableData: [],
      current_flow_total: 0,
      class_id: "",
    };
  },
  components: {
    scoreChart,
    searchToolbar,
  },
  methods: {
    getOperationComponents() {
      let vm = this;
      getOperationComponentsS().then((res) => {
        if (res.code == 1) {
          vm.operationComponents = res.data;
        }
      });
    },
    getSummaries(param) {
      let vm = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总分";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if (column.property == "score") {
            vm.current_flow_total = sums[index];
          }
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
    openScoreDetail: function (arr) {
      this.curRow = arr;
      this.curActive = 1;
      let vm = this;
      this.dialogVisible = true;
      // 用户信息
      this.current_user_info.username = arr["member_name"];
      this.current_user_info.school_name = arr["school_name"];
      this.current_user_info.class_name = arr["class_name"];
      this.current_user_info.class_bank = arr["class_bank"];
      // 评分项得分
      vm.operationTableData = [];
      vm.operationComponents.forEach((element) => {
        let temp = {
          name: element.name,
          percent: element.percent + "%",
          score: arr[element.user_statistic_field],
        };
        vm.operationTableData.push(temp);
      });
      // 统计用户的运营分数
      vm.orderTableData.forEach((o_element) => {
        o_element.orders_total = arr["orders_total"];
        o_element.refund_orders_count = arr["refund_orders_count"];
        o_element.return_orders_count = arr["return_orders_count"];
        o_element.valid_orders_count = arr["valid_orders_count"];
      });
      // 计算当前的利润分
      vm.profitTableData.forEach((p_element) => {
        p_element.profit_fee = arr["profit_fee"];
        p_element.platform_fee = arr["platform_fee"];
        p_element.sales_fee = arr["sales_fee"];
        p_element.platform_commission_fee = arr["platform_commission_fee"];
        p_element.adversting_fee = arr["adversting_fee"];
        p_element.purchase_fee = arr["purchase_fee"];
        p_element.amazon_ship_fee = arr["amazon_ship_fee"];
        p_element.order_ship_fee = arr["order_ship_fee"];
      });

      getUserModuleScoreDetail({
        school_student_string: arr["school_student_string"],
        school_id: arr["school_id"],
        class_id: arr["class_id"],
      }).then((res) => {
        if (res.code == 1) {
          vm.teacherTableData = res.data;
        } else {
          vm.teacherTableData = [];
        }
      });

      // 获取相应用户的流程分
      getFlowDetailS({
        school_student_string: arr["school_student_string"],
        school_id: arr["school_id"],
        class_id: arr["class_id"],
      }).then((res) => {
        if (res.code == 1) {
          vm.flowTableData = res.data;
        } else {
          vm.flowTableData = [];
        }
      });
    },
    handleBankCurrentChange: function (val) {
      this.bankpage = val;
      this.getBankList();
    },
    bank_export: function (data) {
      exportTrainingScore({
        page: this.bankpage,
        page_size: this.bankpageSize,
        ...data,
      }).then((res) => {
        let time = Date.now();
        time = this.formatTimeA(time, 1);
        funDownload(res, "实训成绩-" + time + ".xlsx");
        this.$message.success("下载成功");
      });
    },
    bank_search: function (data) {
      this.search_data = data;
      this.bankpage = 1;
      this.getBankList();
    },
    getBankList: function () {
      let vm = this;
      if (vm.class_id != 0) {
        vm.search_data.class_id = vm.class_id;
      }
      vm.loading = true;
      getScoreBankList({
        page: vm.bankpage,
        page_size: vm.bankpageSize,
        ...vm.search_data,
      }).then((res) => {
        if (res.code == 1) {
          vm.scoreBanktableData = res.data.list;
          vm.scoreBankTotalCount = parseInt(res.data.total);
          vm.$refs.scorebanktoolbar.setSchoolId(vm.teacher_school_id);
          if (vm.class_id != 0) {
            vm.$refs.scorebanktoolbar.setClassId(vm.class_id);
          }
        }
        vm.loading = false;
      });
    },
  },
  created: function () {
    // this.getScoreBankList();
    // this.getOrderBankList();
    // this.getProfitBankList();
    this.getBankList();
    this.getOperationComponents();
  },
};
</script>
<style lang="less" scoped>
#smartai_score_manage_page {
  background: #fff;
  padding: 20px;
  .title {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    .s {
      font-size: 14px;
      color: #ee4d2d;
      border-bottom: 2px solid #ee4d2d;
      padding: 0 10px;
      padding-bottom: 10px;
    }
  }
}
</style>