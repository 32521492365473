<template id="search_toolbar">
  <div class="demo-input-suffix">
    <template v-if="is_show_school">
      <span>学校名称：</span>
      <select v-model="search_data.school_id" @change="handleSchoolChange">
        <option value="0">请选择</option>
        <option
          v-for="(sc_item, sc_index) in school_list"
          :value="parseInt(sc_item.school_id)"
          :key="sc_index"
        >
          {{ sc_item.school_name }}
        </option>
      </select>
    </template>
    <template v-if="is_show_class">
      <span style="font-szie: 14px">所属班级：</span>

      <el-select
        clearable
        style="margin-right: 10px"
        size="mini"
        v-model="search_data.class_id"
        placeholder="全部"
        @clear="searchClick"
      >
        <el-option label="全部" value="0"></el-option>
        <el-option
          v-for="(class_item, class_index) in comClassList"
          :value="class_item.id"
          :key="class_index"
          :label="class_item.class_name"
        ></el-option>
      </el-select>
    </template>
    <template v-if="is_show_username">
      <span style="font-szie: 14px">用户名：</span>
      <el-input
        clearable
        @clear="searchClick"
        style="width: 200px"
        v-model="search_data.username"
        size="mini"
        placeholder="用户名"
      ></el-input>
    </template>
    <template v-if="is_show_teacher">
      <span>教师评分模块：</span>
      <select v-model="search_data.module_id">
        <option value="0">请选择</option>
        <option
          v-for="(module_item, module_index) in comModuleList"
          :key="module_index"
          :value="module_item.id"
        >
          {{ module_item.module_name }}
        </option>
      </select>
      <select
        v-model="search_data.teacher_status"
        v-if="search_data.module_id != 0"
      >
        <option value="0">待评分</option>
        <option value="1">已评分</option>
      </select>
    </template>

    <el-button
      size="mini"
      @click="searchClick"
      type="primary"
      style="margin-left: 10px"
      >查询</el-button
    >
    <el-button size="mini" @click="exportClick" type="success">导出</el-button>
    <div v-if="is_show_sort" style="padding-top: 15px">
      <el-radio-group
        v-model="search_data.sort"
        size="small"
        @change="handleClick"
      >
        <el-radio label="1">按总成绩排名</el-radio>
        <el-radio label="2">按流程分排名</el-radio>
        <el-radio label="3">按运营分排名</el-radio>
        <el-radio label="4">按利润分排名</el-radio>
        <el-radio label="5">按教师评分排名</el-radio>
      </el-radio-group>
    </div>
    <el-divider></el-divider>
  </div>
</template>
<script>
import { getClassList } from "@/api/admin.js";
export default {
  props: {
    is_show_school: {
      type: Boolean,
      default: true,
    },
    is_show_class: {
      type: Boolean,
      default: true,
    },
    is_show_username: {
      type: Boolean,
      default: true,
    },
    is_show_teacher: {
      type: Boolean,
      default: true,
    },
    is_show_sort: {
      type: Boolean,
      default: false,
    },
    is_show_btn_search: {
      type: Boolean,
      default: true,
    },
    is_show_btn_statistics: {
      type: Boolean,
      default: true,
    },
    is_show_btn_export: {
      type: String,
      //   default: true,
    },
    school_list: {
      type: Array,
      //   default: [],
    },
    class_list: {
      type: Array,
      //   default: [],
    },
    moduleList: {
      type: Array,
      //   default: [],
    },
  },
  data: function () {
    return {
      comClassList: [],
      comModuleList: [],
      search_data: {
        school_id: "",
        class_id: "",
        username: "",
        teacher_status: "",
        module_id: "",
        sort: "1",
      },
    };
  },
  methods: {
    handleClick: function () {
      this.$emit("st-sort-click", this.search_data);
    },
    exportClick: function () {
      this.$emit("st-export-click", this.search_data);
    },
    searchClick: function () {
      this.$emit("st-search-click", this.search_data);
    },
    setSchoolId: function (school_id) {
      this.search_data.school_id = school_id;
      this.handleSchoolChange();
    },
    setClassId: function (class_id) {
      this.search_data.class_id = class_id;
    },
    setModuleList: function (arr) {
      this.comModuleList = arr;
      this.search_data.teacher_status = 0;
    },
    handleSchoolChange: function () {
      let vm = this;
      getClassList().then((res) => {
        if (res.code == 1) {
          vm.comClassList = res.data;
        }
      });
      //   $.ajax({
      //     type: "POST",
      //     url: "index.php?act=score_smartai&op=getschoolclass",
      //     data: {
      //       school_id: this.search_data.school_id,
      //     },
      //     dataType: "json",
      //     success: function (res) {
      //       if (res.code == 200) {
      //         vm.comClassList = res.data;
      //         //vm.search_data.class_id='';
      //       }
      //     },
      //   });
    },
  },
  created: function () {
    this.comClassList = this.class_list;
  },
};
</script>
<style  scoped>
.demo-input-suffix {
  padding: 10px 0;
}
</style>