<template id="date_btn">
  <div class="date_btn">
    <span
      v-for="item in dateBtns"
      :key="item.value"
      class="span_time"
      :class="{ active: type == item.value }"
      @click="setOpeaTime(item.value)"
      >{{ item.name }}</span
    >
    <el-date-picker
      size="mini"
      @change="changeOpeateDate"
      v-model="date"
      type="daterange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd"
    >
    </el-date-picker>
    <span v-if="mode" style="font-szie: 14px; margin-left: 10px">店铺：</span>
    <el-select
      @change="handleChange"
      clearable
      v-if="mode"
      size="mini"
      v-model="value"
      placeholder="全部"
    >
      <el-option
        v-for="item in classList"
        :key="item.shop_id"
        :label="item.company_name"
        :value="item.shop_id"
      >
      </el-option>
    </el-select>
  </div>
</template>
<script>
export default {
  name: "date_btn",
  props: {
    mode: {
      type: Boolean,
      default: false,
    },
    classList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      date: [],
      type: "0",
      dateBtns: [
        { name: "今日", value: "0" },
        { name: "本周", value: "1" },
        { name: "本月", value: "2" },
      ],
      value: "",
      options: [],
      shop_id: "",
    };
  },
  methods: {
    setOpeaTime(val) {
      this.type = val;
      this.date = [];
      let obj = {
        type: val,
        data: ["", ""],
        shop_id: this.shop_id,
      };
      this.$emit("select_date", obj);
    },
    changeOpeateDate(val) {

      this.date = val;
      this.type = "3";
      let obj = {
        type: "3",
        data: val,
        shop_id: this.shop_id,
      };
      this.$emit("select_date", obj);
    },
    handleChange(val) {
      let vm = this;
      let obj = {
        type: vm.type,
        data: vm.date,
        shop_id: val,
      };
      this.$emit("select_date", obj);
    },
  },
};
</script>
<style>
.date_btn .span_time {
  display: inline-block;
  font-family: "PingFang SC ", "PingFang SC";
  font-weight: 400;
  font-size: 12px;
  color: #aaaaaa;
  /* line-height: 18px; */
  padding: 0 8px;
  cursor: pointer;
}
.date_btn .span_time.active {
  color: #50acef;
}
</style>